import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import {
  TextPic,
  Text,
  Pic,
  PicWrapper,
  BannerWrapper,
} from "../components/TextComponents/styled"
import ScrollAnimation from "../animation/scrollAnimation"
import "../animation/animate.css"

export default function ScoutPage({ data, location }) {
  return (
    <Layout
      title="Gau Alt-Burgund | Pfadfinder"
      description="Informationen über Pfadfinder"
      location={location}
    >
      {/* Stories */}
      <TextPic>
        <Text>
          <h2>Was sind Pfadfinder?</h2>
          <p>
            Wer oder Was Pfadfinder sind lässt sich am besten anhand der 10
            Pfadfindergesetze erklären:
            <p>Christliche Pfadfinderinnen und Pfadfinder ...</p>
          </p>
          <p>1. ...sind aufrichtig in Gedanken, Worten und Taten.</p>
          <p>2. ...sind zuverlässig und hilfsbereit.</p>
          <p>
            <p> 3. ...verlieren in Schwierigkeiten nicht den Mut. </p>

            <p> 4. ...schützen die Natur und bewahren die Schöpfung.</p>

            <p> 5. ...leben einfach und können verzichten.</p>

            <p> 6. ...fügen sich aus freiem Willen in die Gemeinschaft ein.</p>

            <p> 7. ...sind kameradschaftlich und treu.</p>

            <p>
              {" "}
              8. ...setzen sich für Frieden ein und lösen Streit ohne Gewalt.
            </p>

            <p> 9. ...nehmen Rücksicht und achten ihre Mitmenschen.</p>

            <p>
              10. ...tragen zur Freundschaft aller Pfadfinderinnen und
              Pfadfinder auf aller Welt bei.
            </p>
            <a
              href={
                "https://vcp-rps.de/wordpress/wp-content/uploads/2014/01/VCP-RPS_Landesordnung_Stand_2016.pdf"
              }
            >
              VCP-RPS Landesordnung
            </a>
            <span> 14.03.2019</span>
          </p>
          <br />
          <p>
            Das sind die Richtlinien, an denen wir unsere Arbeit orientieren. Es
            sind keine konkreten Regelungen, wie unser Pfadfinderalltag
            auszusehen hat, sondern grundsätzliche Ideale, die uns alle
            verbinden. Die Pfadfindergeschichte beginnt mit Robert Baden-Powell,
            der die Jugendbewegung gründete, um Kindern und Jugendlichen aus
            allen gesellschaftlichen Schichten die Natur und das Gute
            beizubringen. 1907 fand das erste Lager auf der südenglischen Insel
            Brownsea statt und darüber schrieb er sein zweites Buch “Scouting
            for boys”. Schon zwei Jahre später fand ein großes Pfadfindertreffen
            statt, auf der auch Mädchen darum baten, eine Pfadfindergruppe zu
            gründen und so entstanden die girl guides unter der Leitung Powells
            Schwester. Ab 1925 waren viele bekannte Gruppen aus Deutschland
            unter dem Begriff “bündische Jugend” vereint. Dazu gehörte der Bund
            der Wandervögel und auch die Pfadfinder. Die Wandervögel und ihre
            Tradition nahmen einen großen Einfluss auf unser Verständnis der
            Pfadfinderei. Sie verstanden sich als einen Kreis aus Freunden,
            unabhängig von Staat, Partei oder Religion. Gemeinsam gingen sie
            wandern, sangen viel und genossen die Natur als Rückzugsort. Auch
            das Prinzip “Jugend führt Jugend” und unsere Zelte kommen
            ursprünglich von den Wandervögeln. Der Verband Christlicher
            Pfadfinderinnen und Pfadfinder Rheinland-Pfalz/Saar wurde 1972 auf
            einer Landesversammlung gegründet und wächst seitdem beständig. Auch
            auf Bundesebene sind wir aktiv und engagieren uns.
          </p>
          <span>Inhalt: </span>
          <a href={"https://vcp-rps.de/pfadfinden/geschichte/"}>
            https://vcp-rps.de/pfadfinden/geschichte/
          </a>
          <span> 14.03.2019</span>
        </Text>
        {/*
        <Pic imageWidth={240}>
          <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
            <PicWrapper>

              <Img fluid={data.kohte.childImageSharp.fluid} />
            </PicWrapper>
          </ScrollAnimation>
          <h3>Eine Kohte</h3>
        </Pic>
  */}
      </TextPic>

      {/* Zwischenbanner */}
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <BannerWrapper>
          {/* eslint-disable-next-line */}
          <Img fluid={data.banner.childImageSharp.fluid} />
        </BannerWrapper>
      </ScrollAnimation>

      {/* Stories */}
      <TextPic>
        <Text>
          <h2>Was machen wir?</h2>
          <p>
            Unsere Pfadfinderarbeit besteht im Kern aus wöchentlichen
            Gruppenstunden, Fahrten und (Zelt-)Lagern. Die Gruppenstunden werden
            von den Stämmen vor Ort ausgerichtet. Gruppen bestehen meist aus 4-8
            Kindern oder Jugendlichen in ungefähr demselben Alter und einem oder
            zwei Gruppenführern. Was in den Gruppenstunden gemacht wird hängt
            stark vom Alter ab. In jüngeren Gruppen wird viel gespielt, in
            älteren Gruppen kommen dann Pfadfindertechnik und Beschäftigung mit
            Inhalten der Pfadfinderarbeit dazu. Zeltlager gibt es ab der
            Wölflingsstufe. Auf Gauebene planen wir jährlich mehrere Lager, dazu
            kommen Lager auf Stammesebene. Oft finden Lager übers Wochenende
            statt, aber es gibt auch längere Lager z.B. über Pfingsten oder in
            den Ferien. Für die Älteren gibt es auch die Möglichkeit, an
            Großlagern teilzunehmen: Auf Landesebene, Bundesebene oder
            internationaler Ebene. Diese Lager finden in zwei- oder
            vierjährlichem Rhythmus statt. Fahrten sind eine Form der Wanderung,
            die aus der Jugendbewegung stammt. Dabei sind wir meist als Gruppe
            mit unserem traditionellen Zelt, der Kohte, unterwegs. Wir kochen,
            laufen und zelten zusammen. Fahrten können lokal in der Umgebung
            stattfinden, aber selbstverständlich auch an anderen Plätzen in- und
            außerhalb von Deutschland.
          </p>
        </Text>
        <Pic imageWidth={240}>
          <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
            <PicWrapper>
              {/* eslint-disable-next-line */}
              <Img fluid={data.kohte.childImageSharp.fluid} />
            </PicWrapper>
          </ScrollAnimation>
          <h3>Eine Kohte</h3>
        </Pic>
      </TextPic>
      <br />
      <br />
    </Layout>
  )
}

// queries
export const query = graphql`
  query {
    kohte: file(relativePath: { eq: "kohte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    banner: file(relativePath: { eq: "banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 10000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    jurte: file(relativePath: { eq: "jurte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
